<template>
  <SEmptyStateView
    v-bind="fullConfigs"
    @onButtonClick="onButtonClick"
    @onSecondButtonClick="onSecondButtonClick"
    @onLinkClick="onLinkClick"
  >
    <template #icon>
      <Icon
        :name="fullConfigs.iconName"
        :size="fullConfigs.iconSize"
        is-responsive-name
      />
    </template>
  </SEmptyStateView>
</template>
<script setup>
import { computed } from 'vue'
import { Icon } from '@shein-aidc/icon-vue2'
import { EmptyStateView as SEmptyStateView } from '@shein/sui-mobile'

const props = defineProps({
  language: {
    type: Object,
    default: () => {}
  },
  // ERROR|EMPTY 网络错误｜无数据
  type: {
    type: String,
    default: 'EMPTY'
  },
  // 配置项(可选)
  configs: {
    type: Object,
    default: () => {}
  },
})

const defaultConfigs = computed(() => {
  if (props.type === 'EMPTY') {
    return {
      content: 'It is empty here :-(',
      iconName: 'sui_icon_empty_75px_color',
      iconSize: '75px',
    }
  }
  return {
    content: 'Please connect to a network !',
    buttonNumber: 1,
    buttonText: 'Retry',
    iconName: 'sui_icon_empty_offnet_75px_color',
    iconSize: '75px',
  }
})

const fullConfigs = computed(() => {
  return {
    ...defaultConfigs.value,
    ...props.configs,
  }
})

const emits = defineEmits(['on-button-click', 'on-second-button-click', 'on-link-click'])
const onButtonClick = () => {
  emits('on-button-click')
}
const onSecondButtonClick = () => {
  emits('on-second-button-click')
}
const onLinkClick = () => {
  emits('on-link-click')
}
</script>

