export const orderStatusTabs = [
  {
    id: 0,
    orderStatus: 'All orders',
    saParam: { order_status: 'all_orders' },
    title: 'SHEIN_KEY_PWA_16194'
  },
  {
    id: 1,
    orderStatus: 'Unpaid',
    title: 'SHEIN_KEY_PWA_15309'
  },
  {
    id: 2,
    orderStatus: 'Processing',
    title: 'SHEIN_KEY_PWA_15215'
  },
  {
    id: 3,
    orderStatus: 'Shipped',
    title: 'SHEIN_KEY_PWA_15213'
  },
  {
    id: 5,
    orderStatus: 'Review',
    title: 'SHEIN_KEY_PWA_15162'
  },
  {
    id: 4,
    orderStatus: 'Returns',
    title: 'SHEIN_KEY_PWA_15626'
  }
]
