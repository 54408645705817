<template>
  <div class="orderList-tip-box">
    <common-notice
      class="delay-track-text"
      :message="orderListNotice"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import commonNotice from '../../../../common/commonNotice/index.vue'

export default {
  name: 'ListTips',
  components: {
    commonNotice,
  },
  computed: {
    ...mapState('orderList', ['orderListNotice']),
  }
}
</script>
<style lang="less">
.orderList-tip-box {
  .delay-track-text {
    word-break: break-word;
  }
}
</style>
