<template>
  <div
    v-expose="{
      id: daExposeId,
    }"
    class="return-notice"
  >
    <div class="return-notice__content">
      <Icon
        class="return-notice__return-icon"
        name="sui_icon_return_18px"
        size="18px"
      />
      <div
        v-html="template(returnTotal, language.SHEIN_KEY_PWA_31621)"
      ></div>
    </div>
    <div
      v-tap="{
        id: daClickId,
        data: { show_type: 1 }
      }"
      class="return-notice__more"
      @click="clickToReturn"
    >
      <span>{{ language.SHEIN_KEY_PWA_31680 }}</span>
      <Icon
        name="sui_icon_more_right_12px"
        size="12px"
      />
    </div>
  </div>
</template>

<script>
import { template } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue2'
import { tap, expose } from 'public/src/pages/common/analysis/directive.js'

export default {
  name: 'WaitSendReturnNotice',
  components: {
    Icon
  },
  directives: {
    tap,
    expose
  },
  props: {
    language: {
      type: Object,
      default: () => {},
    },
    returnTotal: {
      type: Number,
      default: 0,
    },
    billno: {
      type: String,
      default: '',
    },
    returnOrderId: {
      type: String,
      default: '',
    },
    noticeTitle: {
      type: String,
      default: '',
    },
    daClickId: {
      type: String,
      default: '',
    },
    daExposeId: {
      type: String,
      default: '',
    },
  },
  methods: {
    template,
    clickToReturn() {
      const queryObj = {
        // billno: this.billno,
        // return_order_id: this.returnOrderId
      }
      // Object.keys(queryObj).forEach(key => {
      //   if (!queryObj[key]) {
      //     delete queryObj[key]
      //   }
      // })
      this.$router.push({
        name: 'returnPackageList',
        query: queryObj
      })
    },
  }
}
</script>
<style lang='less' scoped>
.return-notice {
  .flexbox();
  .align-center();
  padding: 20/75rem 24/75rem;
  background: @sui_color_prompt_common;
  line-height: normal;
  justify-content: space-between;
  &__return-icon {
    margin-right: 16/75rem;
  }
  &__content {
    .flexbox();
    .align-center();
  }
  &__more {
    .flexbox();
    .align-center();
    white-space: nowrap;
    font-weight: bold;
    margin-left: 24/75rem;
    cursor: pointer;
  }
}
</style>
