import orderExpireTimeDown from './orderExpireTimeDown.vue'
import orderReturnItem from './orderReturnItem.vue'
import OrderListItem from './components/OrderListItem.vue'
import listItem from './listItem.vue'
import CommentReward from './CommentReward.vue'
import listTips from './listTips.vue'
import SupportIcon from '@customerService/components/SupportIcon.vue'
import EditAddressBtnNew from 'public/src/pages/user/child_pages/orders/detail/components/editAddressBtnNew'
import FindOrder from '../find_order/FindOrder.vue'
import PayNowBtn from './components/PayNowBtn.vue'
import EmptyState from 'public/src/pages/components/EmptyState.vue'
import WaitSendReturnNotice from 'public/src/pages/user/child_pages/orders/common/WaitSendReturnNotice.vue'
import ClientOnly from 'vue-client-only'
import { LazyMount } from '@shein/sui-mobile'
import { 
  sui_icon_nav_delete_24px,
  sui_icon_more_right_16px,
  sui_icon_nav_back_24px,
  sui_icon_nav_search_24px,
} from '@shein-aidc/icon-vue2'


export default {
  'order-expire-time-down': orderExpireTimeDown,
  SupportIcon,
  orderReturnItem,
  listItem,
  OrderListItem,
  CommentReward,
  listTips,
  EditAddressBtnNew,
  FindOrder,
  LazyMount,
  ClientOnly,
  PayNowBtn,
  EmptyState,
  sui_icon_nav_delete_24px,
  sui_icon_more_right_16px,
  sui_icon_nav_back_24px,
  sui_icon_nav_search_24px,
  WaitSendReturnNotice,
  MyPackageEntrance: () =>
    import(
      /* webpackChunkName: "OrdersList_Async_Chunk" */ './components/MyPackageEntrance.vue'
    ),
  'cancel-mask': () =>
    import(
      /* webpackChunkName: "OrdersList_Async_Chunk" */ '../common/cancel_reason.vue'
    ),
  Recommend: () =>
    import(
      /* webpackChunkName: "OrdersList_Async_Chunk" */ './components/recommend.vue'
    ),
  googlepayDialog: () =>
    import(
      /* webpackChunkName: "OrdersList_Async_Chunk" */ 'public/src/pages/checkout/page_tpls/googlepay_dialog.vue'
    ),
  repurchaseConfirmDialog: () =>
    import(
      /* webpackChunkName: "OrdersList_Async_Chunk" */ '../common/repurchaseConfirmDialog.vue'
    ),
  successPanel: () =>
    import(
      /* webpackChunkName: "OrdersList_Async_Chunk" */ 'public/src/pages/user/child_pages/orders/common/successPanel.vue'
    ),
  payResultTips: () =>
    import(
      /* webpackChunkName: "OrdersList_Async_Chunk" */ 'public/src/pages/common/orderLogic/payResultTips.vue'
    ),
  cancelItemComp: () =>
    import(
      /* webpackChunkName: "OrdersList_Async_Chunk" */ '../common/cancelItem/index.vue'
    ),
  cancelItemTip: () =>
    import(
      /* webpackChunkName: "OrdersList_Async_Chunk" */ '../common/cancelItemTipComp.vue'
    ),
  cancelRelationGoodItem: () =>
    import(
      /* webpackChunkName: "OrdersList_Async_Chunk" */ './cancelRelationGoodItem.vue'
    ),
  confirmDeliveryTips: () =>
    import(
      /* webpackChunkName: "OrdersList_Async_Chunk" */ './confirmDeliveryTips.vue'
    ),
  CustomerConfirmAddress: () =>
    import(
      /* webpackChunkName: "OrdersList_Async_Chunk" */ 'public/src/pages/components/confirmAddress/index.vue'
    ),
  EditAddressConfirmDialog: () =>
    import(
      /* webpackChunkName: "OrdersList_Async_Chunk" */ 'public/src/pages/user/child_pages/orders/common/edit-address/EditAddressConfirmDialog.vue'
    ),
  UrgeDeliveryModal: () =>
    import(
      /* webpackChunkName: "OrdersList_Async_Chunk" */ 'public/src/pages/user/child_pages/orders/common/urge-delivery/UrgeDeliveryModal.vue'
    ),
  CardBinDiscountArticle: () =>
    import(
      /* webpackChunkName: "CardBinDiscountArticle_OrderList" */ 'public/src/pages/components/CardBinDiscountArticle.vue'
    ),
  ChooseBankDrawer: () =>
    import(
      /* webpackChunkName: "ChooseBankDrawer_Checkout" */ 'public/src/pages/checkout/vue_tpls/ChooseBankDrawer.vue'
    ),
  bankCapitecDialog: () =>import(/* webpackChunkName: "bank_capitec_dialog" */ 'public/src/pages/checkout/vue_tpls/dialog/bank_capitec_dialog.vue'),
  commonTipsDialog: () =>
    import(
      /* webpackChunkName: "OrdersList_Async_Chunk" */ 'public/src/pages/user/child_pages/orders/common/commonTipsDialog.vue'
    ),
  commonSelectiveTipsDialog: () =>
    import(
      /* webpackChunkName: "OrdersList_Async_Chunk" */ 'public/src/pages/user/child_pages/orders/common/commonSelectiveTipsDialog.vue'
    ),
  combinedOrderDialog: () =>
    import(
      /* webpackChunkName: "OrdersList_Async_Chunk" */ './components/combinedOrderDialog.vue'
    ),
  addCartDialog: () =>
    import(
      /* webpackChunkName: "OrdersList_Async_Chunk" */ 'public/src/pages/user/child_pages/orders/common/addCartDialog.vue'
    ),
  applePayDialog: () =>
    import(
      /* webpackChunkName: "OrdersList_Async_Chunk" */ './components/applePayDialog.vue'
    ),
  boletoEmailDialog: () =>
    import(
      /* webpackChunkName: "OrdersList_Async_Chunk" */ './components/boletoEmailDialog.vue'
    ),
  qiwiPhoneDialog: () =>
    import(
      /* webpackChunkName: "OrdersList_Async_Chunk" */ './components/qiwiPhoneDialog.vue'
    ),
  editAddressTipsDialog: () =>
    import(
      /* webpackChunkName: "OrdersList_Async_Chunk" */ 'public/src/pages/user/child_pages/orders/common/editAddressTipsDialog.vue'
    ),
  msgBoxDialog: () =>
    import(
      /* webpackChunkName: "OrdersList_Async_Chunk" */ './components/msgBoxDialog.vue'
    ),
  RiskCommentDialog: () =>
    import(
      /* webpackChunkName: "OrdersList_Async_Chunk" */ 'public/src/pages/user/child_pages/orders/common/RiskCommentDialog.vue'
    ),
  UrgentPickUp: () => import(/* webpackChunkName: "OrdersList_Async_Chunk" */ 'public/src/pages/user/child_pages/order_return/records/components/urgentPickUp.vue'),
}
