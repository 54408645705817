<template>
  <div
    v-expose="{
      id: '1-20-1-123',
      data: {
        page_type: isOrderSearch
          ? emptyOrder
            ? ' page_order_search_empty'
            : 'page_order_search_end'
          : emptyOrder
            ? 'page_order_list_empty'
            : 'page_order_list_end'
      }
    }"
    class="findorder-wrapper"
  >
    <div class="findorder-title1">{{ language?.SHEIN_KEY_PWA_21296 }}</div>
    <div class="findorder-title2wapper">
      <div v-if="isShowCard">
        <div
          class="findorder-title2wapperbtn"
          @click="ralationAccount"
        >
          <span class="findbtn-text">
            {{ language?.SHEIN_KEY_PWA_29926 }}
          </span>
          <sui_icon_more_right_16px_2
            :is-rotate="publicLocalData.GB_cssRight"
            size="16px"
          />
        </div>
      </div>
      <div
        v-if="abtInfos?.findOrderAbt?.FindOrderSwitch == 'on'"
        class="findorder-title2wapperbtn"
        @click="toserverFind"
      >
        <span class="findbtn-text">
          {{ language?.SHEIN_KEY_PWA_29927 }}
        </span>
        <sui_icon_more_right_16px_2
          :is-rotate="publicLocalData.GB_cssRight"
          size="16px"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { expose } from 'public/src/pages/common/analysis/directive.js'
import { sui_icon_more_right_16px_2 } from '@shein-aidc/icon-vue2'
import schttp from 'public/src/services/schttp/index'
import { mapState } from 'vuex'

daEventCenter.addSubscriber({ modulecode: '1-20-1' })

export default {
  components: {
    sui_icon_more_right_16px_2,
  },
  directives: { expose },
  computed: {
    ...mapState('orderList', ['language', 'abtInfos', 'publicLocalData', 'orders', 'relationAccount', 'orderSearchMsg']),
    emptyOrder () {
      return this.orders?.length == 0
    },
    isShowCard () {
      return this.abtInfos?.orderlistRelatedIconAbt == 'on' && this.relationAccount
    },
    isOrderSearch () {
      return this.orderSearchMsg.isSearchList
    }
  },
  methods: {
    async handleAccountCardQueryCheck() {
      daEventCenter.triggerNotice({
        daId: '1-20-1-50',
        extraData: {
          type: this.emptyOrder ? 'new_null' : 'new'
        }
      })
      const data = await this.queryRelationAccountSer({ cache: '0' })

      const { code, tips, info } = data

      if (code == 0 && info.exists_relation_account == '1') {
        const list = info.accounts || []
        const actionCb = ({} = {}) => {}
        const action_val = {
          list,
          abt: this.abtInfos?.relationAccountAbt,
          cb: actionCb,
          from: 'order_list'
        }
        const loginCb = () => {
          location.reload()
        }
        const vparams = {
          show: true,
          cb: loginCb,
          instance: 'relationOrder',
          action: 'relation_account',
          action_val,
          bi: 'orderlist_related_account'
        }
        SHEIN_LOGIN.show(vparams)
        return
      }
      this.$toast(tips || this.language.SHEIN_KEY_PWA_14899)
    },
    async queryRelationAccountSer(params) {
      let res = await schttp({
        method: 'POST',
        url: '/api/auth/relation/query',
        data: params
      })
      return res || {}
    },
    toserverFind() {
      this.reportDa('1-20-1-124', {
        page_type: this.isOrderSearch
          ? this.emptyOrder
            ? ' page_order_search_empty'
            : 'page_order_search_end'
          : this.emptyOrder
            ? 'page_order_list_empty'
            : 'page_order_list_end'
      })
      this.$router.push({
        name: 'findorder',
        params: {
          abt: this.abtInfos?.findOrderAbt
        }
      })
    },
    reportDa(id, parmas) {
      daEventCenter?.triggerNotice({
        daId: id,
        extraData: {
          ...parmas
        }
      })
    },
    ralationAccount() {
      this.reportDa('1-20-1-125', {
        page_type: this.isOrderSearch
          ? this.emptyOrder
            ? ' page_order_search_empty'
            : 'page_order_search_end'
          : this.emptyOrder
            ? 'page_order_list_empty'
            : 'page_order_list_end'
      })
      this.handleAccountCardQueryCheck()
    }
  }
}
</script>

<style scoped lang="less">
.findorder-wrapper {
  margin-top: 24/75rem;
  display: flex;
  flex-direction: column;
  padding: 40/75rem 24/75rem;
  background: #fff;
}
.findorder-title1 {
  text-align: center;
  width: 100%;
  color: var(---sui_color_main, #222);
  .font-dpr(28px);
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.002px;
  margin-bottom: 32/75rem;
}
.findorder-title2wapper {
  display: flex;
  flex-direction: column;
  gap: 24/75rem;
}
.findorder-title2wapperbtn {
  border: 1px solid var(---sui_color_gray_weak1, #e5e5e5);
  padding: 12px;
  display: flex;
  justify-content: space-between;
}
.findbtn-text {
  color: var(---sui_color_gray_dark1, #222);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
